import React, { useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';

import MainWidth from '../../Common/MainWidth';

export interface IHero {
  setLoaded: (val: boolean) => void;
}

const Hero = ({ setLoaded }: IHero) => {
  useEffect(() => {
    setLoaded(true);
  }, []);

  const [isAnimating, setIsAnimating] = useState(true); 
  const [typingInterval, setTypingInterval] = useState(300);

  var ReactRotatingText = require('react-rotating-text');
  const items = ['PURPOSE ', 'QUALITY ', 'JOY ', 'PASSION ', 'PURPOSE'];
  let count = 0;

  const checkLast = () => {
    setTypingInterval(115);
    count++;
    if (count == items.length-1){
      setIsAnimating(false)
    } 
  }

  return (
    <section id="hero" className="jumbotron">
      <MainWidth>
        <Container id="svg_container">
          <h1 id="titleContent" className="hero-title">
            SOFTWARE
            <br />
            <span className="title_showAfter">WITH </span>
            <span className="title_showAfter purpose">
              {isAnimating ? <ReactRotatingText name='rotating' typingInterval={typingInterval} deletingInterval={80} items={items} onTypingEnd={() => checkLast()}/> : items[items.length-1]}
            </span>
          </h1>
        </Container>
      </MainWidth>
    </section>
  );
};

export default Hero;
